<template>
  <van-pull-refresh v-model="isLoading" pulling-text="Tarik ke bawah untuk refresh..."
    loosing-text="Lepaskan untuk refresh..." success-text="Refresh Berhasil" @refresh="onRefresh">
    <div class="loan-status">
      <BaseHeaderBar :showLogOut="false" :showBackButton="true" />
      <div class="content">
        <div class="status1" v-if="orderStatus === 1 || orderStatus === 2 || orderStatus === 10">
          <img src="@/assets/img/jisushenhe.png" alt="" />
          <div>Dalam proses peninjauan</div>
          <span>Nominal pencairan dana tergantung pada hasil akhir evaluasi</span>
        </div>
        <div v-else-if="orderStatus === 3" class="status3">
          <img src="@/assets/img/zhengzaifangkuan.png" alt="" />
          <div>Dalam proses pencairan</div>
          <div class="info">
            <div>
              <p>Jumlah yang diterima</p>
              <p>Rp{{ formatNumberWithCommas(loanInfo.ardrrivalAmount) }}</p>
            </div>
            <div>
              <p>Nama bank penerima</p>
              <p>{{ loanInfo.ardrrivalBank }}</p>
            </div>
            <div>
              <p>Nomor rekening bank penerima</p>
              <p>{{ loanInfo.ardrrivalBankcard }}</p>
            </div>
          </div>
        </div>

        <div v-else-if="orderStatus === 4" class="status4">
          <img src="@/assets/img/zanwufajieqian.png" alt="" />
          <div>Anda tidak dapat melakukan pinjaman saat ini</div>
          <span>
            Untuk saat ini Anda tidak memenuhi persyaratan. Pastikan <br />untuk
            selalu mempertahankan riwayat kredit yang baik.
          </span>
          <a class="daichao" href="https://apps.apple.com/id/app/ovvo-pinjam-uang-online/id6737254133?l=id">
            <img src="@/assets/img/daichao1.png" />
          </a>
          <van-dialog class="tuijian_chanpin" v-model="visible" :showConfirmButton="false" close-on-click-overlay>
            <template #title>
              <p class="tuijian_title">Menyarankan</p>
            </template>
            <div class="tuijian_neirong">
              <img src="@/assets/img/tuijianchapin.png" alt="" />
              <div class="chanpin_name">OVVO - Pinjam Uang Online</div>
              <div class="youdian">
                <span>Rp 9.000.000</span>
              </div>
              <div class="youdian hari">
                <span>92-180 hari</span>
              </div>
              <div @click="onCloseDialog()">
                <a href="https://apps.apple.com/id/app/ovvo-pinjam-uang-online/id6737254133?l=id">
                  <button type="button" class="submit-btn OKE">OKE</button>
                </a>
              </div>
            </div>
          </van-dialog>
        </div>

        <div v-else-if="orderStatus === 5" class="status5">
          <div class="daihuankuan">
            <div class="tilte">Menunggu Pembayaran</div>
            <div class="huankuanriqi">
              Harap pembayaran sebelum tanggal
              {{ loanInfo.bidrllDetails.redrpaymentTime.substr(0, 2) }} bulan
              {{ loanInfo.bidrllDetails.redrpaymentTime.substr(3, 2) }}
              tahun {{ loanInfo.bidrllDetails.redrpaymentTime.substr(6) }}
            </div>
            <div class="yuqitianshu">
              Sudah terlambat {{ loanInfo.bidrllDetails.ovdrerdueDay }} hari
            </div>
            <span class="info" @click="goRepaymenDetail">Detail Pinjaman</span>
            <span class="btn">
              Nominal Pelunasan:Rp{{
                formatNumberWithCommas(
                  loanInfo.bidrllDetails.fudrllrepaymentAmount
                )
              }}
            </span>
            <span class="btn">
              Nominal Pembayaran Perpanjangan:Rp{{
                formatNumberWithCommas(
                  loanInfo.bidrllDetails.exdrhibitionrepaymentaAount
                )
              }}
            </span>
          </div>
          <button type="button" class="confirm-btn" @click="goRepay(2)">
            Perpanjangan
          </button>
          <button type="button" class="confirm-btn" @click="goRepay(1)">
            Pelunasan
          </button>
          <a href="https://www.instagram.com/dana_roke.pinjamanuang?igsh=MTk2bm4zeDJ4eGFlMw%3D%3D&utm_source=qr">
            <img src="@/assets/img/Instagram.png" style="width: 100%; margin-top: 10px" alt="" />
          </a>
          <Discount :money="loanInfo.didrscountAmount"></Discount>
          <Recommendation v-if="loanInfo.redr_loan == 1" :redrpaymentTime="loanInfo.bidrllDetails.redrpaymentTime"
            :orderStatus="orderStatus"></Recommendation>
        </div>

        <div v-else-if="
          orderStatus === 0 || orderStatus === 6 || orderStatus === 7
        " class="loan-status6">
          <div class="loan-status6_content">
            <div class="amountList">
              <p class="loan-title">Nominal Pinjaman</p>
              <div class="loan-amount-sort">
                <div ref="loanItem" v-for="(item, index) in loanInfo.mudrltipleLoanList" :key="index"
                  :class="{ active: activeIndex === index }" @click="selectLoan(item, index)">
                  Rp{{ formatNumberWithCommas(item.loanMoney) }}
                </div>
              </div>
            </div>
            <div class="info-block">
              <div v-if="loanTimers && loanTimers.length">
                <template v-if="hasRate">
                  <div class="row">
                    <div>
                      <span>Tenor Pinjaman</span>
                    </div>
                    <span>{{ loanInfo.bidrgDeadate }} Hari</span>
                  </div>
                </template>
                <template v-else>
                  <div class="jiekuanqixian">
                    <div>
                      <span>Tenor Pinjaman</span>
                    </div>
                    <span>{{ loanTimers[0] }}Hari</span>
                  </div>
                </template>
              </div>
              <div class="row" v-if="hasRate">
                <div>
                  <span>Suku bunga tahunan</span>
                </div>
                <span>{{ loanInfo.dadryRate }}</span>
              </div>
              <div class="ticket" v-if="orderStatus === 6 || orderStatus === 7">
                <span>{{
                  loanInfo.mudrltipleLoanList[activeIndex].discountAmount
                }}</span>
              </div>
            </div>
            <button type="button" class="submit" @click="submit">
              Segera Pengajuan
            </button>
            <a v-if="orderStatus === 0 || orderStatus === 6"
              href="https://www.instagram.com/dana_roke.pinjamanuang?igsh=MTk2bm4zeDJ4eGFlMw%3D%3D&utm_source=qr">
              <img src="@/assets/img/Instagram.png" style="width: 100%; margin-top: 10px" alt="" />
            </a>
          </div>
          <Recommendation :orderStatus="orderStatus"></Recommendation>

          <van-dialog v-model="visible" :showConfirmButton="false" close-on-click-overlay class="no-loan-dialog">
            <div class="dialog_body">
              <img src="@/assets/img/woyaotie.png" alt="" />
              <span class="dialog_title">Tingkatkan Nominal</span>
              <h3 class="tip-title">Bagaimana cara menaikkan limit pinjaman</h3>
              <p class="tip">
                Mempertahankan kebiasaan baik dan selalu melunasi tepat waktu.
                Anda bisa mendapatkan kenaikan limit apabila menghindari
                perilaku keterlambatan pembayaran.
              </p>
              <button type="button" class="confirm-btn" @click="onCloseDialog()">
                Mengerti
              </button>
            </div>
          </van-dialog>
        </div>

        <div v-else-if="orderStatus === 8" class="status8">
          <img src="@/assets/img/zanwufajieqian.png" alt="" />

          <div>Verifikasi KTP gagal</div>
          <span>
            Foto KTP yang Anda unggah tidak jelas, sehingga tidak dapat lolos
            evaluasi. Silakan unggah ulang.
          </span>
          <button type="button" class="modify-btn" @click="goAuthentication">
            Edit data
          </button>
        </div>

        <div v-else-if="orderStatus === 9" class="status9">
          <img src="@/assets/img/zanwufajieqian.png" alt="" />
          <div>Pencairan gagal</div>
          <span>
            Pencairan tidak dapat dilakukan karena data rekening <br />
            bank Anda tidak tersedia. Silakan isi ulang.
          </span>
          <button type="button" class="modify-btn" @click="goBankInfo">
            Edit data
          </button>
          <van-dialog v-model="cancelLoanVisible" :showConfirmButton="false" close-on-click-overlay>
            <p class="cancel-loan-dialog-title">Berhasil dibatalkan</p>
            <p class="tip">
              Pinjaman Anda telah dibatalkan. Anda tidak perlu verifikasi data
              lagi jika ingin melakukan pinjaman.
            </p>
            <button type="button" class="confirm-btn" @click="onCloseDialog('cancelLoanVisible')">
              Mengerti
            </button>
          </van-dialog>
        </div>

        <div v-else-if="orderStatus === 12" class="loan-status12">
          <div class="jiekuanjine">
            <div>
              <span>Jumlah pinjaman</span>
            </div>
            <span> Rp{{ formatNumberWithCommas(loanInfo.lodranAmount) }}</span>
          </div>

          <div class="jiekuanqixian">
            <div>
              <span>Lama Pinjaman</span>
            </div>
            <span>{{ loanInfo.avdrailablePeriod[0] }} Hari</span>
          </div>

          <button type="button" class="withdraw-btn" @click="withdraw">
            Segera Tarik
          </button>
        </div>
        <van-dialog v-model="feeVisible" :showConfirmButton="false" close-on-click-overlay>
          <div class="fee-detail">
            <img class="state12_bg" src="@/assets/img/state12_bg.png" alt="" />
            <p class="fee-title">Rincian Biaya</p>
            <div>
              <p>Nominal Pinjaman</p>
              <p>Rp{{ formatNumberWithCommas(feeDetail.lodranCash) }}</p>
            </div>
            <div>
              <p>Jumlah Hari Pinjaman</p>
              <p>{{ feeDetail.lodranTimes }} Hari</p>
            </div>
            <div class="interest">
              <p>Total Bunga</p>
              <p>
                <span>{{ feeDetail.ordriginalAmount }}</span>
                <span>Rp{{ formatNumberWithCommas(feeDetail.todrtalCost) }}</span>
              </p>
              <div class="describe">
                Voucher pengurangan bunga telah otomatis digunakan (peningkatan
                jumlah pinjaman) {{ feeDetail.didrscountAmount }}
              </div>
            </div>
            <div>
              <p>Jumlah yang diterima</p>
              <p>Rp{{ formatNumberWithCommas(feeDetail.ardrrivalCash) }}</p>
            </div>
            <div class="footer-btn">
              <button type="button" class="cancel-btn" @click="onCloseDialog('feeVisible')">
                Batal
              </button>
              <button type="button" class="ok-btn" @click="onOkFee">
                Konfirmasi
              </button>
            </div>
          </div>
        </van-dialog>
      </div>
    </div>
    <template #loading>
      <span></span>
    </template>
  </van-pull-refresh>
</template>

<script>
import Vue from 'vue'
import { Dialog, Toast, PullRefresh, Overlay } from 'vant'

import request from '@/utils/request'
import dialogVisible from '@/mixins/dialogVisible'
import { getMetadata } from '@/utils/getEquipmentInfo.js'
import Discount from '@/components/discount.vue'
import Recommendation from '@/components/Recommendation.vue'

Vue.use(Toast)
Vue.use(PullRefresh)
Vue.use(Overlay)

export default {
  name: 'Home',
  mixins: [dialogVisible],
  components: {
    [Dialog.Component.name]: Dialog.Component,
    Discount,
    Recommendation
  },
  data () {
    return {
      isLoading: false,
      cancelLoanVisible: false,
      feeVisible: false,
      orderStatus: '',
      loanInfo: {},
      feeDetail: {},
      activeIndex: -1,
      loanTimers: [],
      loanAmount: '',
      hasRate: false
    }
  },
  created () {
    this.getOrderStatus()
  },
  methods: {
    getOrderStatus (isRefresh) {
      request.post('homeDRInterface')
        .then(res => {
          this.orderStatus = res.ordrderStatus
          this.loanInfo = res
          // this.orderStatus = 4
          // this.loanInfo = {
          //   bidrllDetails: {
          //     redrpaymentTime: ''
          //   }
          // }
          this.hasRate = res.ordrderStatus === 0 || (res.ordrderStatus === 7 && res.redr_loan === 0)
          if (this.orderStatus === 4) {
            if (!localStorage.getItem('tuijian_product')) {
              this.visible = true
            }
            localStorage.setItem('tuijian_product', true)
          }
          if (res.ordrderStatus === 12) {
            this.loanAmount = String(res.lodranMaxAmount)
          }
          if (res.ordrderStatus === 0 || res.ordrderStatus === 6 || res.ordrderStatus === 7) {
            this.loanAmount = String(res.lodranMaxAmount)
            let index = -1
            localStorage.removeItem('showTicket')
            // 过滤不能借款的金额
            // if (res.ordrderStatus === 6 || (res.ordrderStatus === 7 && res.redr_loan === 1)) {
            //   this.loanInfo.mudrltipleLoanList = res.mudrltipleLoanList.filter((item) => {
            //     return item.loanStatus
            //   })
            // }
            res.mudrltipleLoanList.forEach((item, i) => {
              if (item.loanStatus) {
                index = i
              }
            })
            if (index > -1) {
              this.activeIndex = index
              this.selectedLoanItem = res.mudrltipleLoanList[index]
              this.loanAmount = String(this.selectedLoanItem.loanMoney)
              this.loanTimers = this.initLoanTimers = this.selectedLoanItem.loanTimers || []
            }
          }
          isRefresh && (this.isLoading = false)
        })
    },
    getFeeDetail (item, loanType) {
      const postData = {
        lodranCash: item.loanMoney,
        lodranTimers: item.loanTimers[0],
        lodranType: loanType
      }
      this.withdrawType = loanType
      request.post('indrterestrateBomb', postData)
        .then(res => {
          this.feeDetail = res
        })
    },
    goRepaymenDetail () {
      this.$router.push('/repaymenDetail')
    },
    goBankInfo () {
      if (this.loanInfo.badrnkStatus) {
        const postData = {
          apdrplicationAmount: this.loanInfo.lodranAmount,
          prdromotionChannel: 'AppStore',
          portraitAdd: 1
        }
        // 获取设备信息
        getMetadata()
          .then(res => {
            // 提交设备信息
            return request.post('deviceInformation', res)
          })
          .then(() => {
            // 提交订单
            return request.post('sudrbmitloanOrder', postData)
          })
          .then(() => {
            this.getOrderStatus()
          })
      } else {
        this.goAuthentication()
      }
    },
    goRepay (repayType) {
      const { ordrderID, padrymentID, fudrllrepaymentAmount, exdrhibitionrepaymentaAount } = this.loanInfo.bidrllDetails
      this.$router.push({
        path: '/repay',
        query: {
          repayType,
          amount: repayType === 1 ? fudrllrepaymentAmount : exdrhibitionrepaymentaAount,
          orderId: ordrderID,
          payID: padrymentID
        }
      })
    },
    selectLoan (item, index) {
      const loanStatus = item.loanStatus
      if (loanStatus) {
        if (index !== this.activeIndex) {
          this.activeIndex = index
          this.selectedLoanItem = item
          this.loanAmount = String(item.loanMoney)
          this.loanTimers = item.loanTimers
        }
      } else {
        this.visible = true
      }
    },
    submit () {
      if (this.selectedLoanItem) {
        if (this.orderStatus === 0 || (this.orderStatus === 7 && this.loanInfo.redr_loan === 0)) {
          this.$router.push({
            path: '/authentication',
            query: {
              amount: this.selectedLoanItem.loanMoney
            }
          })
        } else if (this.orderStatus === 6 || (this.orderStatus === 7 && this.loanInfo.redr_loan === 1)) {
          this.getFeeDetail(this.selectedLoanItem, 2)
          this.feeVisible = true
        }
      } else {
        Toast('Silahkan pilih pinjaman terlebih dahulu')
      }
    },
    onOkFee () {
      if (this.flag) return
      this.flag = true
      const { lodranCash, lodranTimes } = this.feeDetail
      const postData = {
        lodranAmount: lodranCash,
        lodranPeriod: lodranTimes,
        widrthdrawType: this.withdrawType
      }
      request.post('widrthdrawCash', postData)
        .then(() => {
          this.feeVisible = false
          this.orderStatus = 1
        })
        .finally(() => {
          this.flag = false
        })
    },
    goAuthentication () {
      this.$router.push({
        path: '/authentication',
        query: {
          amount: this.loanInfo.lodranAmount
        }
      })
    },
    withdraw () {
      const postData = {
        loanMoney: this.loanInfo.lodranAmount,
        loanTimers: this.loanInfo.avdrailablePeriod
      }
      this.getFeeDetail(postData, 1)
      this.feeVisible = true
    },
    onRefresh () {
      this.getOrderStatus(true)
    }
  }
}
</script>

<style scoped lang="less">
.content {
  position: relative;
  z-index: 2;
  border-radius: 30px 30px 0px 0px;
  padding-top: 40px;
}

.loan-title {
  width: 400px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
  border-radius: 0 0 60px 60px;
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  padding-bottom: 30px;
  padding-top: 20px;
  font-weight: 400;
  background: #af73f9;
}

.loan-amount {
  display: flex;
  justify-content: center;
  margin-top: 23px;

  &>div {
    width: 50px;
    height: 71px;
    line-height: 71px;
    border-radius: 6px;
    font-size: 42px;
    color: #fd411c;
    text-align: center;
    background-color: #fff;

    &:nth-child(n + 2) {
      margin-left: 12px;
    }
  }
}

.time {
  .circle {
    display: flex;
    justify-content: center;

    &>div {
      width: 48px;
      height: 48px;
      border: 1px solid #dadada;
      border-radius: 50%;
      margin-top: 22px;

      &:nth-child(n + 2) {
        margin-left: 19px;
      }

      &.active {
        width: 67px;
        height: 67px;
        line-height: 67px;
        margin-top: 12px;
        font-size: 32px;
        text-align: center;
      }
    }
  }

  .unit {
    line-height: 33px;
    margin-top: 9px;
    font-size: 24px;
    color: #fd411c;
    text-align: center;
  }
}

.loan-amount-sort {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 182px);
  margin-bottom: 32px;
  padding: 0 32px;

  div {
    position: relative;
    width: 182px;
    height: 70px;
    margin-bottom: 34px;
    border-radius: 10px;
    font-size: 30px;
    font-weight: 500;
    color: #666666;
    text-align: center;
    line-height: 70px;
    font-size: 26px;
    font-weight: 600;
    color: #553abb;
    border: 1px solid #8647d1;

    &.active {
      font-weight: bold;
      background: #8341d0;
      color: #fff;
    }

    img {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 35px;
      height: 29px;
    }
  }
}

.loan-status {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: @body-bg-color;
}

.status1,
.status3,
.status4,
.status8,
.status9 {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 30px;

  img {
    margin-top: 90px;
    margin-bottom: 84px;
    width: 650px;
    height: 480px;
    text-align: center;
  }

  div {
    margin-bottom: 38px;
    text-align: center;
    font-size: 34px;
    font-weight: 600;
    color: #ffffff;
  }

  span {
    font-size: 30px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
  }

  .modify-btn {
    .submit-btn;
    width: 670px;
    margin-top: 101px;
  }

  .info {
    width: 100%;

    div {
      height: 148px;
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      padding: 0 30px;
      background: #7761c9;
      border-radius: 40px;

      p {
        font-size: 30px;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
        opacity: 0.8;
      }

      p:nth-child(2) {
        font-size: 36px;
        font-weight: 600;
        color: #ffffff;
      }
    }
  }

  .daichao {
    img {
      height: 330px;
    }
  }
}

.status3 {
  img {
    width: 509px;
    height: 419px;
    margin-bottom: 30px;
  }
}

.status4 {
  .AKUKU {
    box-sizing: border-box;
    width: 100%;
    padding: 0 30px;
    margin-bottom: 20px;
  }

  .tuijian_chanpin {
    width: 570px;
    border-radius: 20px;
    margin-bottom: 0;

    .tuijian_title {
      margin-top: 40px;
      font-weight: bold;
      font-size: 38px;
    }

    .tuijian_neirong {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-bottom: 0;

      img {
        width: 150px;
        height: 150px;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .chanpin_name {
        font-size: 26px;
        margin-bottom: 30px;
        font-weight: bold;
      }

      .youdian {
        margin-bottom: 20px;
        font-size: 26px;
        font-weight: 600;

        span {
          color: red;
          font-size: 50px;
        }
      }

      .hari {
        span {
          font-size: 30px;
        }
      }

      .OKE {
        width: 300px;
        margin: 50px;

        a {
          color: #fff;
        }
      }
    }
  }
}

.status5 {
  margin: 0 40px;

  .daihuankuan {
    position: relative;
    background: linear-gradient(to bottom, #994df8, #d390f9);
    border-radius: 12px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 30px;
    padding-bottom: 20px;
    margin-top: 30px;

    .tilte {
      margin-bottom: 18px;
      font-size: 34px;
      font-weight: 600;
      color: #ffffff;
    }

    .huankuanriqi {
      margin-bottom: 25px;
      font-size: 28px;
      font-weight: 400;
      color: #ffffff;
    }

    .yuqitianshu {
      display: inline-block;
      font-weight: 400;
      color: #ffffff;
      margin-bottom: 32px;
      background: #6f05ae;
      height: 80px;
      line-height: 80px;
      font-size: 26px;
      font-weight: 400;
      padding: 0 30px;
      border-radius: 40px;
    }

    .info {
      min-width: 126px;
      height: 59px;
      background: linear-gradient(to bottom right, #8e49de, #9451de);
      border-radius: 30px 0 30px 0;
      font-size: 26px;
      font-weight: 500;
      color: #ffffff !important;
      position: absolute;
      right: 20px;
      top: 20px;
      text-align: center;
      line-height: 59px;
      padding: 0 15px;
    }

    .btn {
      display: block;
      text-align: center;
      width: 100%;
      height: 96px;
      background: #9856dd;
      border-radius: 12px;
      margin-bottom: 22px;
      color: #fff;
      line-height: 96px;
      font-size: 26px;
      font-weight: 600;
      border-radius: 48px;
    }
  }

  .confirm-btn {
    .submit-btn;
    width: 670px;
    font-size: 32px;
    font-weight: bold;
    color: #ffffff;
    margin-top: 200px;
    margin-bottom: 22px;
  }

  .confirm-btn:nth-child(3) {
    margin-top: 0;
  }

  .confirm-btn:last-child {
    margin-top: 0;
    background: url("~@/assets/img/button_bg2.png") no-repeat;
    background-size: 100% 100%;
  }
}

.loan-status6 {
  .loan-status6_content {
    margin: 0 34px;
    padding-bottom: 300px;
  }

  .amountList {
    background: url("~@/assets/img/jine_list.png") no-repeat;
    background-size: 100% 100%;
    background: linear-gradient(to bottom right, #994df8, #d390f9);
    border-radius: 30px;
  }

  .info-block {
    .row {
      height: 210px;
      padding: 20px 0;
      background: #fff4e9;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: column;
      margin-bottom: 40px;
      background: url("~@/assets/img/jeikaunqixian_bg.png") no-repeat;
      background-size: 100% 100%;

      div {
        display: flex;
        align-items: center;

        span {
          color: #fff;
          font-size: 36px;
          font-weight: 500;
        }
      }

      span {
        font-size: 57px;
        font-weight: 600;
        color: #ffffff;
      }
    }

    .row:nth-child(2) {
      background: url("~@/assets/img/daozhangjine.png") no-repeat;
      background-size: 100% 100%;
    }

    .jiekuanqixian {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;
      height: 94px;
      background: #fff4e9;
      border-radius: 47px;
      margin-top: 30px;

      div {
        display: flex;
        align-items: center;

        img {
          margin-right: 10px;
          width: 43px;
          height: 43px;
        }

        span {
          font-size: 30px;
          font-weight: 500;
          color: #333333;
        }
      }

      span {
        font-size: 28px;
        font-weight: bold;
        color: #333333;
      }
    }

    .ticket {
      position: relative;
      height: 234px;
      background: url("~@/assets/img/ticket.png") no-repeat;
      background-size: 100% 100%;
      margin-top: 38px;

      span {
        position: absolute;
        left: 205px;
        top: 80px;
        font-size: 42px;
        color: #000;
        font-weight: 700;
      }
    }
  }

  .submit {
    .submit-btn;
    width: 650px;
    position: fixed;
    left: 50%;
    bottom: 150px;
    transform: translateX(-50%);
  }

  .no-loan-dialog {
    width: 564px;
    margin-left: auto;
    margin-right: auto;

    .dialog_body {
      padding: 0 20px;

      img {
        width: 481px;
        height: 406px;
        position: absolute;
        left: 50%;
        top: -220px;
        transform: translateX(-50%);
      }
    }

    .dialog_title {
      display: block;
      font-size: 36px;
      color: #fff;
      text-align: center;
      margin-top: 210px;
      margin-bottom: 40px;
      font-weight: 600;
    }
  }

  .tip-title {
    margin-bottom: 30px;
    font-weight: 600;
    text-align: center;
    font-size: 34px;
    font-weight: bold;
    color: #ffffff;
  }

  .tip {
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    color: #bbaafc;
    line-height: 45px;
  }

  .confirm-btn {
    .submit-btn;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.loan-status12 {
  padding: 0 40px;
  padding-top: 70px;

  .jiekuanjine,
  .jiekuanqixian {
    height: 277px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    flex-direction: column;
    padding: 0 40px;
    margin-bottom: 40px;
    background: url("../assets/img/daozhangjine.png") no-repeat;
    background-size: 100% 100%;

    div {
      span {
        font-size: 36px;
        font-weight: 500;
        color: #ffffff;
      }
    }

    span {
      font-size: 47px;
      font-weight: 600;
      color: #ffffff;
    }
  }

  .jiekuanqixian {
    background: url("../assets/img/jeikaunqixian_bg.png") no-repeat;
    background-size: 100% 100%;
  }

  .withdraw-btn {
    .submit-btn;
    width: 670px;
    margin-top: 100px;
    margin-bottom: 98px;
  }
}

.status4_dialog {
  .title {
    padding-top: 40px;
    text-align: center;
    font-weight: 600;
    font-size: 40px;
  }

  .tip {
    padding-left: 49px;
    padding-right: 49px;
    margin-top: 30px;
    font-size: 30px;
    color: #fff;
    text-align: center;
    font-weight: 500;
  }

  button {
    .submit-btn;
    width: 500px;
    height: 88px;
    line-height: 88px;
    border-radius: 8px;
    margin: 80px auto 84px;
    font-size: 30px;
    color: #fff;
  }
}

.fee-title {
  text-align: center;
  font-size: 36px;
  color: #fff;
  margin-top: 120px;
  margin-bottom: 50px;
  font-weight: 600;
}

.fee-detail {
  position: relative;
  padding: 20px 30px;
  margin: 0 auto;

  .state12_bg {
    width: 502px;
    height: 430px;
    position: absolute;
    top: -320px;
    left: 50%;
    transform: translateX(-50%);
  }

  &>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    padding-left: 24px;
    padding-right: 24px;

    p {
      font-size: 30px;
      font-weight: 400;
      color: #ffffff;
    }

    p:nth-child(even) {
      font-weight: 600;
    }
  }

  .interest {
    position: relative;
    padding-bottom: 60px;

    span:nth-child(1) {
      font-weight: 500;
      text-decoration: line-through;
      font-size: 26px;
      margin-right: 10px;
    }

    div {
      position: absolute;
      bottom: -28px;
    }
  }

  .describe {
    margin-bottom: -5px;
    font-size: 26px;
    color: #ff8300;
    line-height: 28px;
  }
}

.footer-btn {
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  margin-bottom: 45px;

  button {
    flex: 1;
    height: 88px;
    line-height: 88px;
    font-size: 30px;
    color: #fff;
    border-radius: 8px;
  }

  .cancel-btn {
    .submit-btn;
    width: 281px;
    background: url(../assets/img/button_bg2.png) no-repeat;
    background-size: 100% 100%;
  }

  .ok-btn {
    .submit-btn;
    width: 281px;
  }
}

/deep/ .van-dialog {
  top: 55%;
}
</style>
