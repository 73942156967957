<template>
  <div class="content">
    <!-- 券 -->
    <van-dialog
      class="ticketDialog"
      v-model="ticketVisible"
      :showConfirmButton="false"
    >
      <div class="ticket">
        <h2>{{ money }}</h2>
        <img
          class="ticket_btn"
          src="@/assets/img/ticket_btn.png"
          alt=""
          @click="ticketClose"
        />
        <img
          class="ticket_close"
          src="@/assets/img/ticket_close.png"
          alt=""
          @click="ticketClose"
        />
      </div>
    </van-dialog>
  </div>
</template>

<script>

import Vue from 'vue'
import { Dialog, Toast } from 'vant'
Vue.use(Toast)
export default {
  props: {
    money: {
      default: 'Rp0'
    }
  },
  data () {
    return {
      ticketVisible: false
    }
  },
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  created () {
    // 券
    if (!localStorage.getItem('showTicket')) {
      this.ticketVisible = true
    }
  },
  methods: {
    // 券弹窗关闭
    ticketClose () {
      Toast('Berhasil ambil, gunakan pada pinjaman berikutnya')
      localStorage.setItem('showTicket', true)
      setTimeout(() => {
        this.ticketVisible = false
      }, 700)
    }
  }
}
</script>

<style scoped lang='less'>
.commentDialog {
  .rise-limit-title {
    .dialog-title;
    padding-left: 24px;
    text-align: left;
  }

  .tip {
    padding-left: 49px;
    padding-right: 49px;
    margin-top: 16px;
    font-size: 26px;
    color: #fff;
    text-align: center;
  }

  .toStore-btn {
    .submit-btn;
    width: 500px;
    height: 88px;
    line-height: 88px;
    border-radius: 8px;
    margin: 80px auto 84px;
    font-size: 30px;
    color: #fff;
  }
  .hiddenIcon {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.ticketDialog {
  background: none;
  width: 700px;
  padding-bottom: 130px;
  .ticket {
    background: none;
    position: relative;
    height: 730px;
    background: url("~@/assets/img/ticket_bg.png") no-repeat;
    background-size: contain;
    background-position: center;
    z-index: 9;
    h2 {
      text-align: center;
      padding-top: 330px;
      color: #fb0003;
      font-size: 35px;
    }
    img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .ticket_btn {
      bottom: 40px;
      width: 300px;
    }
    .ticket_close {
      bottom: -130px;
      width: 100px;
    }
  }
}
/deep/ .van-overlay {
  background-color: rgba(0, 0, 0, 0.85);
}
</style>
